<template>
  <div>包装前抽检</div>
</template>

<script>
export default {
  name: "CheckBeforePack"
}
</script>

<style scoped>

</style>